import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { ICredentials, IPwdChange } from '@/models'

export interface IApiResponse<T> {
    data: T;
    message: string;
    success: boolean;
}

export interface IApi {
    SubmitApproval(): Promise<AxiosResponse<null>>;
    login(credentials: ICredentials): Promise<IApiResponse<IPwdChange | null>>;
    getNews(): Promise<IApiResponse<string[]>>;
}

const LOGIN_URL = '/AJAX/Login.ashx'
const INTERNAL_URL = '/AJAX/API.ashx'

interface IINternalCallData {
    method: string;
    url: string;
    data?: Record<string, unknown> | unknown;
    contenttype?: string;
}

class Api implements IApi {
  private _axiosInstance: AxiosInstance
  constructor (axiosInstance: AxiosInstance) {
    this._axiosInstance = axiosInstance
  }

  private InternalCall<T> (data: IINternalCallData): Promise<AxiosResponse> {
    return this._axiosInstance.post<T>(INTERNAL_URL, { ...data, contenttype: 'application/json' })
  }

  private LoginCall<T> (data: IINternalCallData): Promise<AxiosResponse> {
    return this._axiosInstance.post<T>(LOGIN_URL, { ...data, contenttype: 'application/json' })
  }

  public async login (credentials: ICredentials): Promise<IApiResponse<IPwdChange | null>> {
    const call: AxiosResponse = await this.LoginCall<IApiResponse<IPwdChange | null>>({
      method: 'POST',
      url: '',
      data: credentials
    })
    return call.data
  }

  public async getNews (): Promise<IApiResponse<string[]>> {
    const call: AxiosResponse = await this.InternalCall<IApiResponse<string[]>>({
      method: 'GET',
      url: 'public/news'
    })
    return call.data
  }

  public async SubmitApproval (): Promise<AxiosResponse<null>> {
    const call: AxiosResponse = await this.InternalCall<IApiResponse<null>>({
      method: 'POST',
      url: 'public/cookieapproval',
      data: {
        UserAgent: window.navigator.userAgent
      }
    })
    return call.data
  }
}

export abstract class ApiFactory {
  private static _api: IApi | null

  public static get Api (): IApi {
    if (!this._api) {
      this._api = new Api(axios.create())
    }
    return this._api
  }
}
