import { ApiFactory, IApi } from '@/plugins/axios'
import { GetterTree } from 'vuex'
import { IAppState } from './State'

export enum GetterTypes {
    AJAX = 'ajax',
    SHOW_COOKIE_CONFIRMATION = 'showCookieConfirmation',
    LOGIN_BUTTON_DISABLED = 'loginButtonDisabled'
}

export type Getters<S = IAppState> = {
  [GetterTypes.AJAX] (): IApi;
  [GetterTypes.SHOW_COOKIE_CONFIRMATION] (state: S): boolean;
  [GetterTypes.LOGIN_BUTTON_DISABLED] (state: S): boolean;
}

export const getters: GetterTree<IAppState, IAppState> & Getters = {
  [GetterTypes.AJAX] (): IApi {
    return ApiFactory.Api
  },

  [GetterTypes.SHOW_COOKIE_CONFIRMATION] (state: IAppState): boolean {
    return state.CheckedStorage && !state.UserApproved
  },

  [GetterTypes.LOGIN_BUTTON_DISABLED] (state: IAppState): boolean {
    return !state.UserApproved
  }
}
