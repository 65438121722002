import { MutationTree } from 'vuex'
import { IAppState } from './State'

export enum MutationTypes {
    SET_APPROVAL = 'setApproval',
    SET_COOKIE_POLICY_URL = 'setCookiePolicyUrl'
}

export type Mutations<S = IAppState> = {
  [MutationTypes.SET_APPROVAL] (state: S, payload: boolean): void;
  [MutationTypes.SET_COOKIE_POLICY_URL] (state: S, payload: string): void;
}

export const mutations: MutationTree<IAppState> & Mutations = {
  [MutationTypes.SET_APPROVAL] (state: IAppState, payload: boolean): void {
    state.UserApproved = payload
  },

  [MutationTypes.SET_COOKIE_POLICY_URL] (state: IAppState, payload: string): void {
    state.CookiePolicyUrl = payload
  }
}
