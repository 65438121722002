import { createStore } from 'vuex'
import { vuexLocalStorage } from '@/plugins/vuex-persist'
import { actions } from './Actions'
import { mutations } from './Mutations'
import { DefaultState, IAppState } from './State'
import { getters } from './Getters'

export { MutationTypes } from './Mutations'
export { ActionTypes } from './Actions'
export { GetterTypes } from './Getters'
export { IAppState } from './State'

export const store = createStore<IAppState>({
  state: DefaultState,
  mutations,
  actions,
  getters,
  plugins: [vuexLocalStorage.plugin]
})
