import VuexPersistence from 'vuex-persist'
import { IAppState } from '@/store/State'
import { MutationTypes } from '@/store'
import { Payload } from 'vuex'

export const vuexLocalStorage = new VuexPersistence<IAppState>({
  // storage key
  key: 'cookie-approval-status',

  // storage type
  storage: window.localStorage,

  // state properties to store
  reducer: (state: IAppState) => ({
    UserApproved: state.UserApproved
  }),

  filter (mutation: Payload) {
    return mutation.type === MutationTypes.SET_APPROVAL
  }
})
