export type IAppState = {
  CheckedStorage: boolean;
  UserApproved: boolean | null;
  CookiePolicyUrl: string;
}

export const DefaultState: IAppState = {
  CheckedStorage: true,
  UserApproved: null,
  CookiePolicyUrl: ''
}
