<template>
  <div class="container-fluid" id="login">
    <component :is="'link'" rel="stylesheet" type="text/css" :href="bootstrapCssUrl" />
    <component :is="'link'" rel="stylesheet" type="text/css" :href="bootstrapCustomCssUrl" />
    <component :is="'link'" rel="stylesheet" type="text/css" :href="pointCssUrl" />
    <component :is="'link'" rel="stylesheet" type="text/css" :href="loadOverlayHiderCssUrl" />

    <div id="loadOverlay">
      <LoadingSpinner />
    </div>
    <Information />
    <News />
    <Login />
    <CookieConfirm v-if="showCookieConfirmation" />
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, ref, Ref } from 'vue'
import CookieConfirm from '@/components/cookieConfirm/CookieConfirm.vue'
import Information from '@/components/Information.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import Login from '@/components/Login.vue'
import News from '@/components/News.vue'
import { GetterTypes, MutationTypes, store } from './store'

export default defineComponent({
  name: 'App',
  props: {
    policyUrl: {
      type: String,
      required: true
    }
  },
  components: {
    CookieConfirm,
    Information,
    LoadingSpinner,
    Login,
    News
  },
  setup (props) {
    const cdnUrl = process.env.VUE_APP_CDN_URL
    const bootstrapCssUrl = `${cdnUrl}/point/styles/bootstrap.min.css`
    const bootstrapCustomCssUrl = `${cdnUrl}/point/styles/bootstrap_custom.css`
    const pointCssUrl = `${cdnUrl}/point/styles/POINT.css`
    const loadOverlayHiderCssUrl = `${cdnUrl}/point/styles/loadOverlayHider.css`

    store.commit(MutationTypes.SET_COOKIE_POLICY_URL, props.policyUrl)

    // data
    const showAlbertoInfo: Ref<boolean> = ref(false)

    // computed
    const showCookieConfirmation: ComputedRef<boolean> = computed(() => {
      return store.getters[GetterTypes.SHOW_COOKIE_CONFIRMATION]
    })

    return {
      bootstrapCssUrl,
      bootstrapCustomCssUrl,
      pointCssUrl,
      loadOverlayHiderCssUrl,
      showAlbertoInfo,
      showCookieConfirmation
    }
  }
})
</script>

<style lang="scss">
.note-text {
  color: #989FB4;
  font-size: 10px;
}

#information {
  & .assessment img {
    padding-right: 4px;
  }
}

#news {
  & img {
    padding-right: 4px;
  }
}

#login {
  & #point-home-img {
    position: relative;
    left: -45px;
  }
}

#alberto-ad {
  & #alberto-logo {
    cursor: pointer;
    width: 100%;
  }

  & .blue-arrow {
    height: 25px;
    margin-right: 1rem;
  }

  & #static-helix {
    width: 100%;
  }

  & #emerge-logo {
      width: 100%;
  }

  @media only screen and (min-width: 768px) {
    #alberto-logo {
      width: 50%;
    }
    #static-helix {
      width: 35%;
    }
    #emerge-logo {
        width: 60%;
    }
  }
}

#loadOverlay {
  background-color: #f3f2f4;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;

  & .loader {
    margin-left: 0px;
    position: relative;

    & .dot {
      -webkit-animation-name: movement;
              animation-name: movement;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
              animation-timing-function: ease-in-out;
      height: 10px;
      position: absolute;
      top: -10px;
      transform: translate3d(0, -25px, 0) scale(1);
      width: 10px;
    }
    & .dot:nth-of-type(1) {
      -webkit-animation-delay: -0.1s;
              animation-delay: -0.1s;
      left: 150px;
    }
    & .dot:nth-of-type(1)::before {
      -webkit-animation-delay: -0.1s;
              animation-delay: -0.1s;
    }
    & .dot:nth-of-type(2) {
      -webkit-animation-delay: -1.2s;
              animation-delay: -1.2s;
      left: 150px;
    }
    & .dot:nth-of-type(2)::before {
      -webkit-animation-delay: -1.2s;
              animation-delay: -1.2s;
    }
    & .dot:nth-of-type(3) {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s;
      left: 125px;
    }
    & .dot:nth-of-type(3)::before {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s;
    }
    & .dot:nth-of-type(4) {
      -webkit-animation-delay: -1.4s;
              animation-delay: -1.4s;
      left: 125px;
    }
    & .dot:nth-of-type(4)::before {
      -webkit-animation-delay: -1.4s;
              animation-delay: -1.4s;
    }
    & .dot:nth-of-type(5) {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s;
      left: 100px;
    }
    & .dot:nth-of-type(5)::before {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s;
    }
    & .dot:nth-of-type(6) {
      -webkit-animation-delay: -1.6s;
              animation-delay: -1.6s;
      left: 100px;
    }
    & .dot:nth-of-type(6)::before {
      -webkit-animation-delay: -1.6s;
              animation-delay: -1.6s;
    }
    & .dot:nth-of-type(7) {
      -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s;
      left: 75px;
    }
    & .dot:nth-of-type(7)::before {
      -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s;
    }
    & .dot:nth-of-type(8) {
      -webkit-animation-delay: -1.8s;
              animation-delay: -1.8s;
      left: 75px;
    }
    & .dot:nth-of-type(8)::before {
      -webkit-animation-delay: -1.8s;
              animation-delay: -1.8s;
    }
    & .dot:nth-of-type(9) {
      -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s;
      left: 50px;
    }
    & .dot:nth-of-type(9)::before {
      -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s;
    }
    & .dot:nth-of-type(10) {
      -webkit-animation-delay: -2s;
              animation-delay: -2s;
      left: 50px;
    }
    & .dot:nth-of-type(10)::before {
      -webkit-animation-delay: -2s;
              animation-delay: -2s;
    }
    & .dot:nth-of-type(11) {
      -webkit-animation-delay: -1.1s;
              animation-delay: -1.1s;
      left: 25px;
    }
    & .dot:nth-of-type(11)::before {
      -webkit-animation-delay: -1.1s;
              animation-delay: -1.1s;
    }
    & .dot:nth-of-type(12) {
      -webkit-animation-delay: -2.2s;
              animation-delay: -2.2s;
      left: 25px;
    }
    & .dot:nth-of-type(12)::before {
      -webkit-animation-delay: -2.2s;
              animation-delay: -2.2s;
    }
    & .dot:nth-of-type(13) {
      -webkit-animation-delay: -1.3s;
              animation-delay: -1.3s;
      left: 0px;
    }
    & .dot:nth-of-type(13)::before {
      -webkit-animation-delay: -1.3s;
              animation-delay: -1.3s;
    }
    & .dot:nth-of-type(14) {
      -webkit-animation-delay: -2.4s;
              animation-delay: -2.4s;
      left: 0px;
    }
    & .dot:nth-of-type(14)::before {
      -webkit-animation-delay: -2.4s;
              animation-delay: -2.4s;
    }
    & .dot:nth-of-type(15) {
      -webkit-animation-delay: -1.5s;
              animation-delay: -1.5s;
      left: -25px;
    }
    & .dot:nth-of-type(15)::before {
      -webkit-animation-delay: -1.5s;
              animation-delay: -1.5s;
    }
    & .dot:nth-of-type(16) {
      -webkit-animation-delay: -2.6s;
              animation-delay: -2.6s;
      left: -25px;
    }
    & .dot:nth-of-type(16)::before {
      -webkit-animation-delay: -2.6s;
              animation-delay: -2.6s;
    }
    & .dot:nth-of-type(17) {
      -webkit-animation-delay: -1.7s;
              animation-delay: -1.7s;
      left: -50px;
    }
    & .dot:nth-of-type(17)::before {
      -webkit-animation-delay: -1.7s;
              animation-delay: -1.7s;
    }
    & .dot:nth-of-type(18) {
      -webkit-animation-delay: -2.8s;
              animation-delay: -2.8s;
      left: -50px;
    }
    & .dot:nth-of-type(18)::before {
      -webkit-animation-delay: -2.8s;
              animation-delay: -2.8s;
    }
    & .dot:nth-of-type(19) {
      -webkit-animation-delay: -1.9s;
              animation-delay: -1.9s;
      left: -75px;
    }
    & .dot:nth-of-type(19)::before {
      -webkit-animation-delay: -1.9s;
              animation-delay: -1.9s;
    }
    & .dot:nth-of-type(20) {
      -webkit-animation-delay: -3s;
              animation-delay: -3s;
      left: -75px;
    }
    & .dot:nth-of-type(20)::before {
      -webkit-animation-delay: -3s;
              animation-delay: -3s;
    }
    & .dot:nth-of-type(21) {
      -webkit-animation-delay: -2.1s;
              animation-delay: -2.1s;
      left: -100px;
    }
    & .dot:nth-of-type(21)::before {
      -webkit-animation-delay: -2.1s;
              animation-delay: -2.1s;
    }
    & .dot:nth-of-type(22) {
      -webkit-animation-delay: -3.2s;
              animation-delay: -3.2s;
      left: -100px;
    }
    & .dot:nth-of-type(22)::before {
      -webkit-animation-delay: -3.2s;
              animation-delay: -3.2s;
    }
    & .dot:nth-of-type(23) {
      -webkit-animation-delay: -2.3s;
              animation-delay: -2.3s;
      left: -125px;
    }
    & .dot:nth-of-type(23)::before {
      -webkit-animation-delay: -2.3s;
              animation-delay: -2.3s;
    }
    & .dot:nth-of-type(24) {
      -webkit-animation-delay: -3.4s;
              animation-delay: -3.4s;
      left: -125px;
    }
    & .dot:nth-of-type(24)::before {
      -webkit-animation-delay: -3.4s;
              animation-delay: -3.4s;
    }
    & .dot:nth-of-type(25) {
      -webkit-animation-delay: -2.5s;
              animation-delay: -2.5s;
      left: -150px;
    }
    & .dot:nth-of-type(25)::before {
      -webkit-animation-delay: -2.5s;
              animation-delay: -2.5s;
    }
    & .dot:nth-of-type(26) {
      -webkit-animation-delay: -3.6s;
              animation-delay: -3.6s;
      left: -150px;
    }
    & .dot:nth-of-type(26)::before {
      -webkit-animation-delay: -3.6s;
              animation-delay: -3.6s;
    }
    & .dot::before {
      -webkit-animation-name: size-opacity;
              animation-name: size-opacity;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
              animation-timing-function: ease;
      background: white;
      border-radius: 50%;
      content: "";
      display: block;
      height: 100%;
      width: 100%;
    }
    & .dot:nth-of-type(even)::before {
      background-color: #522D61;
      box-shadow: inset 0 0 4px #4a2658;
    }

    @-webkit-keyframes movement {
      0% {
        transform: translate3d(0, -25px, 0);
        z-index: 0;
      }
      50% {
        transform: translate3d(0, 25px, 0);
        z-index: 10;
      }
      100% {
        transform: translate3d(0, -25px, 0);
        z-index: -5;
      }
    }

    @keyframes movement {
      0% {
        transform: translate3d(0, -25px, 0);
        z-index: 0;
      }
      50% {
        transform: translate3d(0, 25px, 0);
        z-index: 10;
      }
      100% {
        transform: translate3d(0, -25px, 0);
        z-index: -5;
      }
    }
    @-webkit-keyframes size-opacity {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      25% {
        transform: scale(1.5);
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.35;
        transform: scale(0.5);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
    @keyframes size-opacity {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      25% {
        transform: scale(1.5);
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.35;
        transform: scale(0.5);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

#confirmation-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin: auto;
  padding: 1rem;
  transform: translate(-50%, 0);
  z-index: 9999;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  border: 1px solid white;
  background-color: black;
  color: white;

  & #approve-btn {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
