import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container-fluid",
  id: "login"
}
const _hoisted_2 = { id: "loadOverlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Information = _resolveComponent("Information")!
  const _component_News = _resolveComponent("News")!
  const _component_Login = _resolveComponent("Login")!
  const _component_CookieConfirm = _resolveComponent("CookieConfirm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent('link'), {
      rel: "stylesheet",
      type: "text/css",
      href: _ctx.bootstrapCssUrl
    }, null, 8, ["href"])),
    (_openBlock(), _createBlock(_resolveDynamicComponent('link'), {
      rel: "stylesheet",
      type: "text/css",
      href: _ctx.bootstrapCustomCssUrl
    }, null, 8, ["href"])),
    (_openBlock(), _createBlock(_resolveDynamicComponent('link'), {
      rel: "stylesheet",
      type: "text/css",
      href: _ctx.pointCssUrl
    }, null, 8, ["href"])),
    (_openBlock(), _createBlock(_resolveDynamicComponent('link'), {
      rel: "stylesheet",
      type: "text/css",
      href: _ctx.loadOverlayHiderCssUrl
    }, null, 8, ["href"])),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LoadingSpinner)
    ]),
    _createVNode(_component_Information),
    _createVNode(_component_News),
    _createVNode(_component_Login),
    (_ctx.showCookieConfirmation)
      ? (_openBlock(), _createBlock(_component_CookieConfirm, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}