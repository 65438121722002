<template>
    <div id="login" class="row loginrow mb-4">
        <div class="col-xl-4 col-lg-4 d-xl-block d-lg-block d-none">
            <div class="align-items-center h-100">
                <img id="point-home-img" :src="homeImgUrl" alt="lfa" />
            </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div class="container-fluid">
                <form class="mt-3 mb-3" @submit.prevent="login">
                  <div class="row">
                    <div class="col-12 mb-3 regularSmallTitle">
                        Existing Users:
                    </div>
                    <div class="col-12 mb-2 regularText">
                        Welcome back. Log in below.
                    </div>
                    <div class="col-12 mb-2">
                        <input name="username" v-model="username" max="50" class="form-control" placeholder="Username/Email Address" />
                    </div>
                    <div class="col-12 mb-2">
                        <input name="password" type="password" v-model="password" max="50" class="form-control" placeholder="Password" />
                    </div>
                  </div>
                  <div class="row" v-show="!loginEnabled">
                    <div class="col-12 mb-2">
                      <p class="note-text">*Please accept cookies below in order to login. The button will remain inert until cookies are accepted. Our cookie policy is linked below.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 col-sm-12 mb-3">
                        <button @click="login" @click.prevent :disabled="!loginEnabled" id="btnLogin" class="btn btn-primary">LOGIN</button>
                    </div>
                    <div class="col-md-10 col-sm-12 mb-3 text-center">
                        <a href="/RecoverPwd.aspx" class="purplelink">Forgot your password?</a>
                    </div>
                    <div class="col-12 mb-2">
                        <span id="lblLoginError" class="regularError">{{errorMsg}}</span>
                    </div>
                  </div>
                </form>

                <div class="row mb-3">
                    <div class="col-12 mb-3 regularSmallTitle">
                        New Users:
                    </div>
                    <div class="col-12 mb-2 regularText">
                        If you don't already have an LFA POINT program account, click below to create one.
                    </div>
                    <div class="col-12 mb-2">
                        <button id="btnRegister" @click="register" class="btn btn-primary w-25">REGISTER</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, PropType, ref, Ref } from 'vue'
import { Store } from 'vuex'
import { IApi, IApiResponse } from '@/plugins/axios'
import { ICredentials, IPwdChange } from '@/models'
import { store, GetterTypes } from '@/store'
import { IAppState } from '@/store/State'

export default defineComponent({
  name: 'login-component',
  props: {
    store: {
      type: Object as PropType<Store<IAppState>>,
      default: (): Store<IAppState> => store
    }
  },
  setup (props) {
    const store: Ref<Store<IAppState>> = ref(props.store)
    const ajax: ComputedRef<IApi> = computed(() => store.value.getters[GetterTypes.AJAX])

    // data
    const username: Ref<string> = ref('')
    const password: Ref<string> = ref('')
    const errorMsg: Ref<string | null> = ref(null)

    // computed
    const loginEnabled: ComputedRef<boolean> = computed(() => {
      return !store.value.getters[GetterTypes.LOGIN_BUTTON_DISABLED]
    })

    // methods
    const setErrorMsg = (message: string | null): void => {
      errorMsg.value = message
    }

    const register = (): void => {
      window.location.href = '/Registration.aspx'
    }

    const login = (): void => {
      const usernameVal = username.value.trim()
      const passwordVal = password.value.trim()
      if (!usernameVal || !passwordVal) {
        setErrorMsg('Please enter a username and password')
        return
      }
      const credentials: ICredentials = {
        username: usernameVal,
        password: passwordVal
      }
      setErrorMsg(null)
      ajax.value.login(credentials).then((response: IApiResponse<IPwdChange | null>) => {
        if (response.success) {
          window.location.href = '/Secure.aspx'
        } else if (response.message === 'Password recovery required' && response.data) {
          window.location.href = `/RecoverPwd_Change.aspx?cr=${response.data.guid}&h=${response.data.hash}`
        } else {
          setErrorMsg(response.message)
        }
      })
    }

    return {
      username,
      password,
      errorMsg,
      loginEnabled,

      homeImgUrl: `${process.env.VUE_APP_CDN_URL}/point/images/menu/Point_Home.jpg`,

      login,
      register,
      setErrorMsg
    }
  }
})
</script>
