<template>
    <div id="news" v-if="hasNews" class="row mb-4">
        <div class="col-12 regularTitle">
            News
        </div>
        <div class="col-12 my-2" v-for="item in news" :key="item">
            <img :src="selectedImgUrl" alt="selected" />
            {{item}}
        </div>
    </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, PropType, Ref, ref } from 'vue'
import { Store } from 'vuex'
import { IApiResponse } from '@/plugins/axios'
import { GetterTypes, IAppState, store } from '@/store'

export default defineComponent({
  name: 'news-update',
  props: {
    store: {
      type: Object as PropType<Store<IAppState>>,
      default: (): Store<IAppState> => store
    }
  },
  setup (props) {
    const store: Ref<Store<IAppState>> = ref(props.store)
    // data
    const news: Ref<string[]> = ref([])

    // computed
    const hasNews: ComputedRef<boolean> = computed(() => {
      return news.value && news.value.length > 0
    })

    // methods
    const getNews = (): void => {
      store.value.getters[GetterTypes.AJAX].getNews()
        .then((response: IApiResponse<string[]>) => {
          if (response.success) {
            news.value = response.data
          }
        })
    }

    return {
      news,
      hasNews,
      selectedImgUrl: `${process.env.VUE_APP_CDN_URL}/point/images/menu/selected.jpg`,
      getNews
    }
  },
  mounted (): void {
    if (!this.hasNews) {
      this.getNews()
    }
  }
})
</script>
