<template>
  <div v-if="displayConfirmation" id="confirmation-container">
    <div>
      <p>LFA-POINT uses only the minimal cookies needed to maintain site functionality.</p>
      <p>If you choose not to accept cookie storage on your browser, you will not be able to log in due to these functionality requirements. To learn more, please view our <a :href="policyUrl">Cookie Policy</a>.</p>
    </div>
    <div id="approve-btn">
      <button class="btn btn-primary btn-sm" @click="approve">Accept Cookies</button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, PropType, ref, Ref } from 'vue'
import { store, ActionTypes, IAppState } from '@/store'
import { Store } from 'vuex'

export default defineComponent({
  name: 'App',
  props: {
    store: {
      type: Object as PropType<Store<IAppState>>,
      default: (): Store<IAppState> => store
    }
  },
  setup (props) {
    const store: Ref<Store<IAppState>> = ref(props.store)
    const checked: ComputedRef<boolean> = computed((): boolean => store.value.state.CheckedStorage)
    const approved: ComputedRef<boolean> = computed((): boolean => store.value.state.UserApproved || false)
    const policyUrl: ComputedRef<string> = computed((): string => store.value.state.CookiePolicyUrl)

    const displayConfirmation: ComputedRef = computed((): boolean => {
      return checked.value && !approved.value
    })

    const approve = (): void => {
      store.value.dispatch(ActionTypes.APPROVE)
    }

    return {
      displayConfirmation,
      policyUrl,
      approve
    }
  }
})
</script>
