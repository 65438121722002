<template>
  <div id="information" class="row mb-4">
    <div class="col-12 container-fluid">
      <div class="row mb-4">
        <div class="col-12 regularTitle mb-3">Welcome</div>
        <div class="col-12 regularText">
          The LFA POINT Program is a web-based training site designed to
          coordinate training and testing for clinical instruments used in the
          assessment of people with lupus for medical professionals in the lupus
          research community.
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12 regularSmallTitleBold">
          LFA POINT currently provides the following training programs:
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 assessment">
          <img :src="selectedImgUrl" alt="selected" />
          BILAG 2004 Index
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 assessment">
          <img :src="selectedImgUrl" alt="selected" />
          SELENA SLEDAI
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 assessment">
          <img :src="selectedImgUrl" alt="selected" />
          SLEDAI 2K
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 assessment">
          <img :src="selectedImgUrl" alt="selected" />
          Hybrid SLEDAI
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 assessment">
          <img :src="selectedImgUrl" alt="selected" />
          SLICC
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 assessment">
          <img :src="selectedImgUrl" alt="selected" />
          CLASI
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 assessment">
          <img :src="selectedImgUrl" alt="selected" />
          Joint Count
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12 mb-2 regularText">
          Each program can be customized to meet your specific needs. If you are
          interested in using LFA POINT, please use the "Contact Us" option on
          the bottom right of the screen for more information.
        </div>
        <div class="col-12 regularText">
          For more information regarding the LFA POINT Program,
          <a href="/About.aspx" class="purplelink">see our about page.</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'point-information',
  setup () {
    return {
      selectedImgUrl: `${process.env.VUE_APP_CDN_URL}/point/images/menu/selected.jpg`
    }
  }
})
</script>
